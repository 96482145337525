<template>
  <div class="license">
    <div class="title">用户协议</div>
    <p>
      欢迎使用无锡中巢建材科技有限公司（以下简称“无锡中巢”）的“测量大师”APP或/和智能硬件及其附带的产品或服务，包括随附在其中的所有文档和文件（
      以下简称“本产品”）。我们将竭诚为您提供更全面、更优质的服务。在您使用本产品前，请您仔细阅读本协议的所有内容。本协议构成您与 “本产品”运营者之间的法律协议。您确认您具备使用本产品和同意本协议的主体资格。
    </p>
    <h3>一、 定义</h3>
    <p>1.1 本协议中，“您”是指使用本产品的一切自然人、法人或非法人组织。</p>
    <p>1.2 本协议中，“使用”是指下载、安装、应用、访问或以其他方式通过利用本产品功能的行为。</p>
    <h3>二、 服务条款的接受</h3>
    <p>当您点击“立即安装”或注册本产品用户或以其他方式实际使用本产品时，即表示您已经认真阅读、同意本协议的全部条款，愿意受本协议之约束，并已完全了解可能存在的风险，且同意承担使用本产品及本产品相关服务可能产生的潜在风险。如果您不同意这些条款，请您终止下载、使用本产品并立即删除本产品及与之相关的文件。
    </p>
    <h3>三、 服务条款的修订</h3>
    <p>3.1
      出于符合监管政策的要求或提高本产品的使用体验之目的，无锡中巢有权随时修改或变更本协议内容，并只需公示于无锡中巢官网而无须征得您的事先同意，请您随时留意无锡中巢官网的动态更新以及手机短信的更新提醒。修改后的条款自在无锡中巢官网公示之日起生效。
    </p>
    <p>3.2 本协议修改或变更后，若您继续使用本产品的，视为您已经阅读、了解并接受修改或变更后的条款。如果您不能接受修改或变更后的协议，您应立即停止使用本产品及相关服务，或者及时告知无锡中巢您的异议后由无锡中巢取消您的用户资格。
    </p>
    <h3>四、 用户账号注册
    </h3>
    <p>4.1 测量大师用户账号注册步骤：登录 无锡中巢官网 或 测量大师 客户端，进入账号注册界面，输入您个人的有效手机号码和即时验证码，即可注册成为无锡中巢测量大师用户。
    </p>
    <p>4.2 一旦您注册成为无锡中巢测量大师用户，即表示您同意授权无锡中巢向您的手机号码发送各类信息包括但不限于账号安全提示信息、手机登录验证码信息、密码更改提示信息、产品介绍信息、优惠活动信息及其他商业广告信息。
    </p>
    <p>4.3
      您的用户账号注册成功后，账号使用权归属于您，账号所有权归属于无锡中巢。您应提供及时、详尽及准确的个人资料，并在您的信息发生变更后及时更新注册资料。如果因您的注册信息不真实、不准确、不合法有效而引起任何问题、争议或纠纷，您须自行承担相关后果及责任，一概与无锡中巢无关。为避免因信息不实引发争议或纠纷，无锡中巢有权停止向您提供服务。
    </p>
    <p>4.4
      您的用户账号和密码仅供您本人使用，不得以任何形式转让或出借予他人使用。如您发现个人账号遭他人非法使用或存在安全漏洞，请您立即通知无锡中巢。因黑客行为或用户的保管疏忽导致账号、密码遭他人非法使用、窃取、泄露的，无锡中巢不承担任何责任。
    </p>
    <p>4.5 如果发现同一账号和密码在同一时间内被多人同时登陆使用等异常情形，无锡中巢可以立即联系此账号的原注册用户，并有权中止或终止此账号的用户资格，且无须承担任何法律责任。
    </p>
    <h3>五、 用户隐私保护</h3>
    <p>尊重和保护用户个人隐私是无锡中巢一贯遵循的重要原则，无锡中巢不对外公开或向第三人提供您的注册资料或个人信息，除非：
    </p>
    <p>5.1 事先获得您的明确授权；
    </p>
    <p>5.2 根据您的需要，只有提供您的个人资料，才能为您申请、提供您所要求的产品和服务；
    </p>
    <p>5.3 履行无锡中巢的法定责任或保护无锡中巢的合法权利之必要；
    </p>
    <p>5.4 司法、行政及其他有权监管机构依照法定程序执法或监管之必要；
    </p>
    <p>
      5.5 根据相应的法律、法规或规章有披露之必要。</p>
    <h3>六、 用户使用权限</h3>
    <p>6.1 下载、安装和使用：本产品为免费，您可以非商业性、无限制数量地下载、安装及使用本产品。
    </p>
    <p>6.2 复制和传播：您可以非商业性、无限制数量地复制和传播本产品，但必须保证每一份复制和传播都是完整和真实的，包括所有有关本产品的软件、电子文档、版权和商标及本协议的全部内容。
    </p>
    <p>6.3 本协议下，本产品服务期限自您的用户账号注册成功之日起计算。
    </p>
    <h3>七、 用户管理
    </h3>
    <p>7.1 为了规范用户使用本产品的行为，请您向无锡中巢承诺如下：</p>
    <p>7.1.1 承诺在遵守法律法规、部门规章、规范性文件及本协议的前提下使用本产品。
    </p>
    <p>7.1.2 承诺不对本产品进行反向工程、反向编译或反向汇编，同时不得改动编译在程序文件内部的任何资源。
    </p>
    <p>7.1.3 本产品作为一个单一产品而被授予许可使用，您承诺不会将本产品各个部分分开用于任何目的。
    </p>
    <p>7.1.4 承诺不以出售、出租、出借、赠与、转让等方式作经营目的使用。
    </p>
    <p>7.1.5 承诺不为任何非法目的或以任何非法方式使用本产品，并承诺遵守相关法律、法规及其他使用互联网产品的国际惯例。
    </p>
    <p>7.1.6 承诺不利用无锡中巢所提供的服务从事任何违法或侵害他人权益行为。
    </p>
    <p>7.1.7 承诺不通过本产品发布、传输任何危害国家安全的、破坏民族团结的、宣扬邪教和封建迷信的、淫秽暴力的、侮辱或诽谤他人的、教唆犯罪的言论、文章、信息等。</p>
    <p>7.1.8 承诺不利用本产品从事非法进入其他计算机信息网络、盗用他人计算机信息资源、恶意篡改计算机数据及应用程序、故意制作及传播计算机病毒等危害计算机信息网络安全的行为。
    </p>
    <p>7.1.9 承诺不实施其他依法应被禁止或限制的行为。</p>
    <p>7.2 您需对自己使用本产品服务过程中的所有行为独立承担法律责任。一经发现您的行为违反上述承诺，无锡中巢有权立即采取如截断传输内容、保存传输记录、关闭用户账号、删除侵权内容链接等措施，必要时可以主动向有关监管机构报告。
    </p>
    <h3>八、 知识产权</h3>
    <p>8.1
      无锡中巢对本产品所使用的软件、程序、产品架构、产品设计等享有独立、完整的所有权及知识产权。任何人不得擅自使用、修改、重制、公开播送、改制、散布、发行、公开发表、进行还原工程、解编、反向编译或以其他方式从本产品取得源代码，或从本产品中摘取其实质部分作其他应用，否则视为侵权。
    </p>
    <p>8.2
      无锡中巢或其他权利人对本产品服务内容中的行情、资讯、热帖文章、图片、资料、档案等享有独立、完整的所有权及知识产权。未经无锡中巢或其他权利人的事前书面同意，任何人不得擅自复制、转载、篡改或商业引用相关服务内容，亦不得利用上述信息内容向他人收取不正当费用，否则视为侵权。
    </p>
    <p>8.3 如果您的行为侵犯无锡中巢或其他权利人的知识产权的，给无锡中巢或其他第三人造成损失的，由您自行承担一切损害赔偿责任包括但不限于诉讼费、取证费及律师费等。
    </p>
    <h3>九、 风险提示</h3>
    <p>9.1
      您应充分认识到证券投资的风险。无锡中巢所提供的所有财经金融资讯和行情数据信息，仅供您参考。您在使用本产品过程中，对于上述信息数据内容，不应将其视为无锡中巢明示、默示地承诺任何金融产品的投资收益，不应将其视为对任何证券或衍生品的具体品种选择/买卖时机的建议，不应将其视为对证券市场及证券品种的走势、投资证券的可行性分析、预测或建议，或任何其他形式的证券投资咨询/建议。
    </p>
    <p>9.2 无锡中巢及其关联公司将竭力确保所提供信息的可靠性和准确性，但不能保证其绝对可靠和准确。您须谨慎判断本产品中的行情价格、图表、评论和购买或者其他信息的准确性，仅作为参考使用。
    </p>
    <p>9.3 如果您依据无锡中巢提供的数据和信息进行证券投资操作的，应自行承担一切风险和损失，无锡中巢对您的投资决策可能带来的风险或损失不承担任何违约、侵权或其他民事责任。
    </p>
    <h3>十、 免责</h3>
    <p>10.1 您须确认已知悉本产品功能及为实现本产品功能所进行的必要操作，根据自身需求自愿选择使用本产品及相关服务。因使用本产品及相关服务所存在的风险和一切后果将完全由您自行承担，无锡中巢不承担任何责任。
    </p>
    <p>10.2 本产品经过详细、严密的测试，但不能保证与所有的软硬件系统完全兼容，不能保证本产品完全没有错误。如果出现不兼容及软件错误的情况，您可以联系无锡中巢，以便获得相应的技术支持。
    </p>
    <p>10.3 在适用法律允许的最大范围内，对因使用或不能使用本产品所产生的损失及风险，包括但不限于直接或间接的个人损害、商业赢利的丧失、贸易中断、商业信息的丢失或任何其它经济损失，无锡中巢不承担任何责任。
    </p>
    <p>10.4 对于因电信系统或互联网网络故障、计算机故障或病毒、信息损坏或丢失、计算机系统问题或其它任何不可抗力原因而产生的损失，无锡中巢不承担任何责任。
    </p>
    <p>10.5
      您使用本产品时应当自行采取合理的防护措施。本产品所提供的服务因客观因素可能会出现中断、故障等现象，存在造成您使用不便或经济损失之虞。在此情形下，如您因使用或无法使用本产品所提供的服务而遭受损失的，无锡中巢不承担赔偿责任。
    </p>
    <p>10.6 无锡中巢尽力维护本产品的安全性及方便性，但对服务中出现的信息删除或存储失败不承担任何责任。
    </p>
    <p>10.7
      根据公司经营之需要，无锡中巢将会在本产品中刊登、转载合作公司提供的新闻、资讯、交流文章等内容，刊登、转载时均会注明内容提供者。基于尊重内容提供者的知识产权，无锡中巢对其提供的内容不作任何实质性审查或修改，不保证该内容的真实性，请您自行判断。特别提示，对于如下信息，无锡中巢对客户、合作伙伴和供应商不承担任何责任：
    </p>
    <p>10.7.1 任何新闻、文章和信息的准确性；
    </p>
    <p>10.7.2 提供新闻，信息和文章时的任何延迟，不准确，错误，中断或遗漏，以及；
    </p>
    <p>10.7.3 任何新闻，信息和文章的中断。
    </p>
    <p>若您认为某些内容涉及侵权或不实，请自行向该内容的提供者反映意见。</p>
    <p>10.8 无锡中巢发布的任何事实，评估，分析，预测，意见和其他信息（以下简称“信息”）均未考虑您的任何投资目标，财务状况以及特定需求和要求。
      因此，除非另有说明，否则无锡中巢的所有信息仅用于一般信息和/或营销目的，不应被解释为:
    </p>
    <p>10.8.1 财务，投资，税务或会计建议；
    </p>
    <p>10.8.2 金融产品交易的一般推荐；以及
    </p>
    <p>10.8.3 以任何特定方式鼓励您进行投资。
    </p>
    <p>10.8.4 本产品中包含的信息可在世界范围内被访问，但上述信息并不向根据与所在国或所在司法管辖区相关法律相冲突的居民定向提供。
    </p>
    <h3>十一、 服务终止</h3>
    <p>11.1 发生如下任一情形时，无锡中巢的服务终止：</p>
    <p>11.1.1 您拒绝接受修改后的协议条款，或主动卸载本产品，或以其他形式表明不再使用无锡中巢服务的；
    </p>
    <p>11.1.2 因您存在违反本协议的行为，无锡中巢有权立即终止服务，并取消您的用户资格，且无须承担任何违约责任；
    </p>
    <p>11.1.3 因不可抗力事件发生导致无锡中巢无法继续提供服务的，双方均有权终止服务，且无须承担任何违约责任。
    </p>
    <p>前述所称“不可抗力”，是指超出本协议控制范围的，不能预见、不能避免或不能克服的所有事件，该事件使得本协议全部或部分不能被履行。这类事件包括但不限于地震、台风、洪水、火灾、战争、罢工、暴动、政府行为、法律条文或者其适用发生变化，或者其他任何无法预见、避免或者控制的事件，包括在国际商务实践中通常被接受为不可抗力的事件。
    </p>
    <p>11.2 无锡中巢服务终止的，您的用户资格自服务终止之日起失效，且您不再享有使用无锡中巢服务的权利，无锡中巢亦无须再向您承担任何义务。
    </p>
    <h3>十二、 其他
    </h3>
    <P>12.1 本协议的订立、生效、履行、终止及争议解决适用香港法律。
    </P>
    <P>12.2 因本协议的订立、生效、履行及终止所引起的争议，无锡中巢希望与您友好协商；如协商无法解决问题或协商结果无法令您满意的，您与无锡中巢一致同意提交香港国际仲裁中心依据其届时现行有效的仲裁规则进行仲裁。
    </P>
    <P>12.3 如果本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，或违反任何适用的法律，则该条款自动失效；但本协议的其余条款仍应有效且有约束力。
    </P>
    <P>12.4 无锡中巢对本协议拥有最终解释权。
    </P>
  </div>
</template>

<script>
export default {
  name: "license",
  data () {
    return {

    }
  }
}
</script>

<style lang="less" scoped>
.license {
  margin: 35px 20px;
  & > div {
    line-height: 25px;
    font-size: 14px;
    margin: 15px 0;
    &.title {
      text-align: center;
      font-size: 30px;
      font-weight: 600;
      line-height: 30px;
    }
  }
  p {
    margin: 8px 0;
    text-indent: 25px;
    font-size: 14px;
    line-height: 25px;
    color: #666666;
  }
  h3 {
    color: #333333;
    font-size: 18px;
    font-weight: 600;
  }
  h4 {
    margin: 10px 0;
    font-weight: 600;
    font-size: 16px;
  }
  h5 {
    line-height: 25px;
    font-weight: 600;
    font-size: 14px;
  }
}
</style>