import { render, staticRenderFns } from "./license.vue?vue&type=template&id=3b5ac1ca&scoped=true&"
import script from "./license.vue?vue&type=script&lang=js&"
export * from "./license.vue?vue&type=script&lang=js&"
import style0 from "./license.vue?vue&type=style&index=0&id=3b5ac1ca&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b5ac1ca",
  null
  
)

export default component.exports